import Swiper, {
    Navigation, Pagination, EffectFade, Thumbs, Controller,
} from 'swiper';

Swiper.use([Navigation, Pagination, EffectFade, Thumbs, Controller]);

const projectsSlider = () => {
    const swiperThumbs = new Swiper('.js--projects-slider-thumbs', {
        loop: false,
        slidesPerView: 1,
        watchOverflow: true,
        effect: 'fade',
        fadeEffect: {
            crossFade: true,
        },
        spaceBetween: 32,
        freeMode: false,
        allowTouchMove: false,
    });
    const swiperSlider = new Swiper('.js--projects-slider', {
        loop: false,
        slidesPerView: 1,
        watchOverflow: true,
        fadeEffect: {
            crossFade: true,
        },
        pagination: {
            el: '.js--projects-slider-nav .swiper-control__fraction',
            type: 'fraction',
            currentClass: 'numbers__current swiper-control__fraction-current',
            totalClass: 'numbers__total swiper-control__fraction-total',
            disabledClass: 'is-disabled',
            hiddenClass: 'is-hidden',
            lockClass: 'is-lock',
            formatFractionCurrent(number) {
                if (number < 10) {
                    number = `0${number}`;
                }
                return number;
            },
            formatFractionTotal(number) {
                if (number < 10) {
                    number = `0${number}`;
                }
                return number;
            },
        },
        navigation: {
            nextEl: '.js--projects-slider-nav .swiper-control__next',
            prevEl: '.js--projects-slider-nav .swiper-control__prev',
            disabledClass: 'is-disabled',
            hiddenClass: 'is-hidden',
            lockClass: 'is-lock',
        },
        spaceBetween: 32,
        thumbs: {
            swiper: swiperThumbs,
        },
    });
};

export default projectsSlider;
