import anime from 'animejs/lib/anime.es';

const geographyWorks = function () {
    const connectScriptMap = function () {
        const script = document.createElement('script');
        script.src = 'https://api-maps.yandex.ru/2.1/?apikey=0e3dec5c-dad9-4dd1-8c99-aebdbcc7754f&lang=ru_RU';
        document.head.append(script);
        script.onload = function () {
            ymaps.ready(setMap('https://grifon-trucks.ru/wa-data/public/site/prices/maps/all_routes.kml', '1'));
        };
    };
    connectScriptMap();

    const spinner = document.querySelector('.js--geography-spinner');

    const mapItems = document.querySelectorAll('.js--map-items .geography__item');
    const mapBlocks = document.querySelectorAll('.js--map-blocks .map-info__block');

    for (let i = 0; i < mapItems.length; i++) {
        mapItems[i].addEventListener('click', function () {
            if (!this.classList.contains('is-active')) {
                spinner.classList.remove('is-hidden');
                setDeactiveMapItems(mapItems);
                this.classList.add('is-active');
                const mapId = this.getAttribute('data-map-id');
                const mapAll = this.getAttribute('data-map-all');
                setActiveMap(mapId);
                destroyMap();
                const mapUrl = this.getAttribute('data-map-url');
                setMap(mapUrl, mapAll);
            }
        });
    }

    const setDeactiveMapItems = function (mapItems) {
        for (let i = 0; i < mapItems.length; i++) {
            mapItems[i].classList.remove('is-active');
        }
    };

    const setDeactiveMapBlocks = function (mapBlocks) {
        for (let i = 0; i < mapBlocks.length; i++) {
            mapBlocks[i].classList.remove('is-active');
        }
    };

    const setActiveMap = function (id) {
        for (let i = 0; i < mapBlocks.length; i++) {
            if (mapBlocks[i].getAttribute('data-map-id') === id) {
                setDeactiveMapBlocks(mapBlocks);
                mapBlocks[i].classList.add('is-active');
            }
        }
    };

    let map;

    const setMap = function (url, all) {
        function init(ymaps) {
            const myMap = new ymaps.Map('js--geography-map', {
                center: [55.75399399999374,37.62209300000001],
                zoom: 6,
            });

            map = myMap;

            ymaps.geoXml.load(url)
                .then((res) => {
                    res.geoObjects.each((obj) => {
                        if (!obj.geometry) {
                            obj.each((obj) => {
                                obj.options.set({
                                    strokeWidth: '4',
                                });
                            });
                        }
                    });
                    onGeoXmlLoad(res);
                });

            function onGeoXmlLoad(res) {
                myMap.geoObjects.add(res.geoObjects);
                if (res.mapState) {
                    res.mapState.applyToMap(myMap);
                    setTimeout(() => {
                        spinner.classList.add('is-hidden');
                    }, 1000);
                } else {
                    if (all !== '1') {
                        myMap.setBounds(res.geoObjects.getBounds());
                    }
                    setTimeout(() => {
                        spinner.classList.add('is-hidden');
                    }, 1000);
                }
            }

            myMap.controls.remove('geolocationControl');
            myMap.controls.remove('searchControl');
            myMap.controls.remove('trafficControl');
            myMap.controls.remove('typeSelector');
            myMap.controls.remove('fullscreenControl');
            myMap.controls.remove('rulerControl');
            myMap.behaviors.disable(['scrollZoom']);
        }
        ymaps.ready(init);
    };

    const destroyMap = function () {
        spinner.classList.remove('is-hidden');
        map.destroy();
    };
};

export default geographyWorks;
