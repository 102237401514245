import appear from 'appear/dist/appear';

const appearItems = (itemsSelector, appearedClass, boundsHeight) => {
    window.appear({
        elements: function elements() {
            return document.querySelectorAll(itemsSelector);
        },
        appear: function appear(el) {
            el.classList.add(appearedClass);
        },
        // disappear: function disappear(el){
        //     el.classList.remove('is-appear');
        // },
        // done: function done() {
        //     console.log('done!!!');
        // },
        bounds: +boundsHeight,
        // reappear: true,
    });
};

export default appearItems;

// import ScrollMagic from 'scrollmagic';
//
// const appearItems = (itemsSelector, appearedClass, boundsHeight) => {
//     // new ScrollMagic.Scene({
//     //     triggerElement: "#trigger1",
//     //     triggerHook: 0.9, // show, when scrolled 10% into view
//     //     duration: "80%", // hide 10% before exiting view (80% + 10% from bottom)
//     //     offset: 50 // move trigger to center of element
//     // })
//     //     .setClassToggle("#reveal1", "visible") // add class to reveal
//     //     .addIndicators() // add indicators (requires plugin)
//     //     .addTo(controller);
//
//
//     var controller = new ScrollMagic.Controller();
//     // build scenes
//     var revealElements = document.querySelectorAll(`${itemsSelector}`);
//     for (var i=0; i<revealElements.length; i++) { // create a scene for each element
//         new ScrollMagic.Scene({
//             triggerElement: revealElements[i], // y value not modified, so we can use element as trigger as well
//             //offset: `${boundsHeight}`,												 // start a little later
//             triggerHook: 0.8,
//         })
//             .setClassToggle(revealElements[i], `${appearedClass}`) // add class toggle
//             .addTo(controller);
//     }
//
//     window.scrollmagic = controller;
//
// };
//
// export default appearItems;
