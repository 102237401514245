function notification(wrapper, type, message) {
    wrapper.find('.muar').addClass('is-visible');
    wrapper.find(`.notification--${type}`).addClass('is-visible');
    if (message) {
        if (message.indexOf('Email has already been taken.') > 0) {
            wrapper.find(`.notification--${type} .notification__text`).html('Email уже занят');
        } else {
            wrapper.find(`.notification--${type} .notification__text`).html(message);
        }
    }
}

function checkout() {
    $('.js--checkout').on('submit', function (e) {
        e.preventDefault();
        const form = $(this);

        if(form.hasClass('is-can-submit')) {
            notification(form, 'success', 'Подождите, формируем счет');

            const userName = form.find('input[name="name"]').val();
            const userEmail = form.find('input[name="email"]').val();
            const userPhone = form.find('input[name="phone"]').val();
            const userCompany = form.find('input[name="company"]').val();
            const userInn = form.find('input[name="inn"]').val();
            const userAddress = form.find('input[name="adr"]').val();
            // const paymentMethodId = form.find('input[name="payment_method"]').val();

            const data = {
                order: {
                    payment_method_id: 1,
                },
                user: {
                    name: userName,
                    email: userEmail,
                    phone: userPhone,
                    property: {
                        company: userCompany,
                        inn: userInn,
                        adr: userAddress,
                    },
                },
            };
            $(this).request('MakeOrder::onCreate', {
                data,
                success: function(obResponse) {
                    if (!obResponse) {
                        notification(form, 'error', 'Непредвиденная ошибка. Обратитесь к менеджеру');
                        return;
                    }

                    if (!!obResponse['X_WINTER_REDIRECT']) {
                        return this.success(obResponse);
                    }

                    if (!obResponse.status) {
                        notification(form, 'error', 'Непредвиденная ошибка. Обратитесь к менеджеру');

                        return;
                    }

                    notification(form, 'success', 'Заказ успешно оформлен!');

                    return this.success(obResponse);
                }
            });
        }
    });
}

export default checkout;
