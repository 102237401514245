import Plyr from 'plyr';
import { animPreloader, animPreloaderAjax } from './components/animations';
import lazyLoad from './components/lazyload';
import appearItems from './components/appear-items';
import flyLabel from './components/fly-label';
import heroHeight from './components/hero-height';
import projectsSlider from './components/projects-slider';
import gallerySlider from './components/gallery-slider';
import fslightbox from './components/fslightbox';
import projectsFilter from './components/projects-flter';
import managementSlider from './components/management-slider';
import projectSlider from './components/project-slider';
import relatedSlider from './components/related-slider';
import mapMaker from './components/map';
import modals from './components/modals';
import setPhoneMask from './components/input-mask';
import search from './components/search';
import geographyWorks from './components/geography';
import postGallerySlider from './components/post-gallery-slider';
import mobileMenu from './components/mobile-menu';
import isMobile from './components/is-mobile';
import addToCart from './components/add-to-cart';
import removeFromCart from './components/remove-from-cart';
import formValidation from './components/validator';
import formUserAccount from './components/form-user-account';
import formChangePassword from './components/formChangePassword';
import checkout from './components/checkout';

isMobile();
mobileMenu();
animPreloader();
animPreloaderAjax();

document.addEventListener('DOMContentLoaded', () => {
    if (document.querySelector('.js--lazyload')) {
        lazyLoad();
    }
    if (document.querySelector('.js--appear-top')) {
        appearItems('.js--appear-top', 'is-appeared-top', '-150');
    }
    if (document.querySelector('.js--appear-swipe')) {
        appearItems('.js--appear-swipe', 'is-appeared-swipe', '-150');
    }
    if (document.querySelector('.js--appear-cover')) {
        appearItems('.js--appear-cover', 'is-appeared-cover', '-150');
    }
    if (document.querySelector('.js--hero-height')) {
        heroHeight();
    }
    if (document.querySelector('.js--fly-label')) {
        flyLabel();
    }
    if (document.querySelector('.js--projects-slider')) {
        projectsSlider();
    }
    if (document.querySelector('.js--gallery-slider')) {
        gallerySlider();
    }
    if (document.querySelector('.js--filter-items')) {
        projectsFilter();
    }
    if (document.querySelector('.js--management-slider')) {
        managementSlider();
    }
    if (document.querySelector('.js--project-slider')) {
        projectSlider();
    }
    if (document.querySelector('.js--related-slider')) {
        relatedSlider();
    }
    if (document.querySelector('#js--map')) {
        mapMaker();
    }
    if (document.querySelector('.modal')) {
        modals();
    }
    if (document.querySelector('.js--phone-mask')) {
        setPhoneMask();
    }
    if (document.querySelector('.js--form')) {
        formValidation('.js--form');
    }
    if (document.querySelector('.js--search-open')) {
        search();
    }
    if (document.querySelector('#js--geography-map')) {
        geographyWorks();
    }
    if (document.querySelector('.js--post-gallery')) {
        postGallerySlider();
    }
    if (document.querySelector('.js--plyr')) {
        const players = Plyr.setup('.js--plyr', {
            controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'fullscreen'],
            ratio: '16:9',
            i18n: {
                speed: 'Скорость',
                normal: 'Нормальная',
            },
        });
    }
    if (document.querySelector('.js--add-to-cart')) {
        addToCart();
    }
    if (document.querySelector('.js--remove-from-cart')) {
        removeFromCart();
    }
    if (document.querySelector('.js--form-user-account')) {
        formUserAccount();
    }
    if (document.querySelector('.js--form-change-password')) {
        formChangePassword();
    }
    if (document.querySelector('.js--checkout')) {
        checkout();
    }
    $('.js--logout').click(() => {
        $.request('Logout::onAjax');
    });

    // $('.js--user-account').on('submit', function (e) {
    //     e.preventDefault();
    //     if($(this).hasClass('is-can-submit')) {
    //         console.log('GOGOGO');
    //     } else {
    //         console.log('STOP');
    //     }
    // });

    // $('.js--user-account').submit((e) => {
    //     console.log('SEND');
    //     e.preventDefault();
    //
    //     console.log('SEdfdfND');
    //     if($(this).hasClass('is-can-submit')) {
    //         console.log('js--user-account');
    //
    //         const form = $(this).closest('form');
    //         const userName = $(this).find('input[name="name"]').val();
    //         const userEmail = $(this).find('input[name="email"]').val();
    //         const userPhone = $(this).find('input[name="phone"]').val();
    //         const userCompany = $(this).find('input[name="company"]').val();
    //         const userInn = $(this).find('input[name="inn"]').val();
    //         const userAddress = $(this).find('input[name="adr"]').val();
    //
    //         console.log(userCompany);
    //
    //         $('.js--login-form').request('UserPage::onAjax', {
    //             success(response) {
    //                 console.log(response.status);
    //                 console.log(response);
    //                 if (response.status === false) {
    //                     //console.log(response);
    //                     let form_group = $('.js--login-form').find(`input[name=${response.data.field}]`).closest('.form-group');
    //                 } else {
    //                     this.success(response);
    //                 }
    //             },
    //         });
    //     }
    // });

    // $('.form-register').submit((e) => {
    //     console.log('ok');
    //     e.preventDefault();
    //     // $('.form-register').request('Registration::onAjax').response(function (response) {
    //     //     console.log(response);
    //     // });
    //
    //     $('.form-register').request('Registration::onAjax', {
    //         success(response) {
    //             console.log(response.status);
    //             if (response.status === false) {
    //                 console.log(response);
    //                 $('.form-register').find(`input[name=${response.data.field}]`).addClass('error');
    //             } else {
    //                 this.success(response);
    //             }
    //         },
    //     });
    // });

    // $('.js--login-form').submit((e) => {
    //     console.log('ok');
    //     e.preventDefault();
    //     // $('.form-register').request('Registration::onAjax').response(function (response) {
    //     //     console.log(response);
    //     // });
    //
    //     $('.js--login-form').request('Login::onAjax', {
    //         success(response) {
    //             console.log(response.status);
    //             if (response.status === false) {
    //                 console.log(response);
    //                 let form_group = $('.js--login-form').find(`input[name=${response.data.field}]`).closest('.form-group');
    //                 form_group.addClass('has-error');
    //                 form_group.find()
    //             } else {
    //                 this.success(response);
    //             }
    //         },
    //     });
    // });

    // $.request('Registration::onCheckEmail', {
    //     data: {'email': $('input[name="email"]').val()},
    //     success: function(data) {
    //         if(data.status) {
    //             //Email is available
    //         } else {
    //             //Email is not available
    //         }
    //     }
    // });
});
