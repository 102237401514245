const mobileMenu = () => {
    function setMobileMenu() {
        if (window.innerWidth < 1024) {
            const menuOpener = document.querySelector('.js--mobile-menu-opener');
            menuOpener.addEventListener('click', function () {
                const mobileMenu = document.querySelector('.js--mobile-menu');
                const body = document.querySelector('body');
                if (this.classList.contains('is-opened')) {
                    this.classList.remove('is-opened');
                    mobileMenu.classList.remove('is-opened');
                    body.classList.remove('is-scroll');
                } else {
                    this.classList.add('is-opened');
                    mobileMenu.classList.add('is-opened');
                    body.classList.add('is-scroll');
                }
            });

            let submenuOpener = document.querySelectorAll('.js--submenu-opener');
            for (let i = 0; i < submenuOpener.length; i++) {

                submenuOpener[i].addEventListener('click', function () {
                    cleanSubmenuOpener();
                    if (this.classList.contains('is-opened')) {
                        this.classList.remove('is-opened');
                    } else {
                        this.classList.add('is-opened');
                    }
                    checkMobileSocial();
                });
            }

            function checkMobileSocial() {
                let showSocial = 1;
                let mobileSocial = document.querySelector('.mobile-social');
                for (let i = 0; i < submenuOpener.length; i++) {
                    if (submenuOpener[i].classList.contains('is-opened')) {
                        showSocial = 0;
                    }
                }
                if (showSocial === 0) {
                    mobileSocial.classList.add('is-hidden');
                } else {
                    mobileSocial.classList.remove('is-hidden');
                }
            }

            function cleanSubmenuOpener() {
                for (let i = 0; i < submenuOpener.length; i++) {
                    submenuOpener[i].classList.remove('is-opened');
                }
            }
            if (!document.querySelector('.hero__subtitle')) {
                const mobileMenu = document.querySelector('.mobile-menu');
                mobileMenu.classList.add('is-ready');
            }
        }
    }

    window.addEventListener('resize', () => {
        setMobileMenu();
    });
    setMobileMenu();
};

export default mobileMenu;
