import Swiper from 'swiper';

const managementSlider = () => {
    const swiper = new Swiper('.js--management-slider', {
        slidesPerView: 3,
        spaceBetween: 32,
        loop: false,
        watchOverflow: true,
        pagination: {
            el: '.js--management-slider-nav .swiper-control__fraction',
            type: 'fraction',
            currentClass: 'swiper-control__fraction-current',
            totalClass: 'swiper-control__fraction-total',
            disabledClass: 'is-disabled',
            hiddenClass: 'is-hidden',
            lockClass: 'is-lock',
            formatFractionCurrent(number) {
                if (number < 10) {
                    number = `0${number}`;
                }
                return number;
            },
            formatFractionTotal(number) {
                if (number < 10) {
                    number = `0${number}`;
                }
                return number;
            },
        },
        navigation: {
            nextEl: '.js--management-slider-nav .swiper-control__next',
            prevEl: '.js--management-slider-nav .swiper-control__prev',
            disabledClass: 'is-disabled',
            hiddenClass: 'is-hidden',
            lockClass: 'is-lock',
        },
    });
};

export default managementSlider;

