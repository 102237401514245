function addToCart() {
    $('.js--add-to-cart').on('click', function () {
        const offerId = $(this).data('offer-id');

        const data = {
            cart: [
                { offer_id: offerId, quantity: 1 },
            ],
            shipping_type_id: '',
            payment_method_id: 1,
        };

        $.request('Cart::onAdd', {
            data,
            update: { 'site/bottom-panel': '.bottom-panel' },
            beforeUpdate() {
                $('.post__in-cart').html('Данное видео добавлено в корзину. Чтобы приобрести его нажмите "Перейти в корзину"');
            },
        });
    });
}

export default addToCart;
