const isMobile = () => {
    function check() {
        const elemContent = document.querySelector('.content');
        if (window.innerWidth < 1024) {
            elemContent.classList.remove('is-desktop');
            elemContent.classList.add('is-mobile');
        } else {
            elemContent.classList.remove('is-mobile');
            elemContent.classList.add('is-desktop');
        }
    }

    window.addEventListener('resize', () => {
        check();
    });
    check();
};

export default isMobile;
