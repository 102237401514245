const search = () => {
    const body = document.querySelector('body');
    const searchOpener = document.querySelector('.js--search-open');
    const searchClose = document.querySelector('.js--search-close');
    const searchPanel = document.querySelector('.js--search-panel');
    const muar = document.querySelector('.js--main-muar');
    const searchInput = document.querySelector('.js--search-panel input');
    const searchButton = document.querySelector('.js--search-panel button');

    const closeSearch = () => {
        muar.classList.remove('is-visible');
        searchOpener.classList.remove('is-open');
        searchPanel.classList.remove('is-visible');
        body.classList.remove('is-search');
    };

    const openSearch = () => {
        muar.classList.add('is-visible');
        searchOpener.classList.add('is-open');
        searchPanel.classList.add('is-visible');
        body.classList.add('is-search');
    };

    searchOpener.addEventListener('click', function () {
        if (this.classList.contains('is-open')) {
            closeSearch();
        } else {
            openSearch();
        }
    });

    searchClose.addEventListener('click', () => {
        closeSearch();
    });

    document.addEventListener('click', (e) => {
        const { target } = e;

        if (
            !target.closest('.js--search-open')
            && !target.closest('.js--search-panel')
            && body.classList.contains('is-search')
        ) {
            closeSearch();
        }
    });

    const searchValidate = () => {
        const searchLength = searchInput.value.length;
        const searchError = document.querySelector('.js--search-panel .search-panel__error');
        if (searchLength > 2 && searchLength < 251) {
            searchError.classList.remove('is-error');
            searchError.innerHTML = '';
            searchButton.classList.remove('is-disabled');
        } else if (searchLength > 251) {
            searchError.classList.add('is-error');
            searchError.innerHTML = 'Слишком много символов';
            searchButton.classList.add('is-disabled');
        } else if (searchLength > 0 && searchLength < 3) {
            searchError.classList.add('is-error');
            searchError.innerHTML = 'Слишком мало символов';
            searchButton.classList.add('is-disabled');
        } else if (searchLength === 0) {
            searchError.classList.add('is-error');
            searchError.innerHTML = 'Задан пустой поисковый запрос';
            searchButton.classList.add('is-disabled');
        }
    };

    searchInput.addEventListener('keyup', () => {
        searchValidate();
    });
    searchButton.addEventListener('click', (e) => {
        e.preventDefault();

        searchValidate();

        if (!searchButton.classList.contains('is-disabled')) {
            document.querySelector('.search-panel__form').submit();
        }
    });
};

export default search;
