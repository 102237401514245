import appearItems from './appear-items';
import lazyLoad from './lazyload';

function removeFromCart() {
    $('.post').on('click', '.js--remove-from-cart', function () {
        const offerId = $(this).data('offer-id');

        let data = {
            cart: [offerId],
            shipping_type_id: '',
            payment_method_id: 1
        };

        $.request('Cart::onRemove', {
            data,
            update: { 'cart/cart-positions': '.cart-positions' },
            complete() {
                if ($('.projects-list').children().length === 0) {
                    location.reload();
                }
                appearItems('.js--appear-top', 'is-appeared-top', '-150');
                lazyLoad();
            }
        });
    });
}

export default removeFromCart;
