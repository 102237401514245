const projectsFilter = function () {
    const filters = document.querySelectorAll('.js--filter-items');

    for (let i = 0; i < filters.length; i++) {
        const controls = filters[i].querySelector('.controls');
        const controlItems = controls.querySelectorAll('.control');
        const allMixItems = filters[i].querySelectorAll('.mix-item');

        function addAnimationTop(allMixItems) {
            for (let i = 0; i < allMixItems.length; i++) {
                if (!allMixItems[i].classList.contains('a-appear-top')) {
                    allMixItems[i].classList.add('a-appear-top');
                }
                if (!allMixItems[i].classList.contains('js--appear-top')) {
                    allMixItems[i].classList.add('js--appear-top');
                }
            }
        }

        addAnimationTop(allMixItems);

        function setVisibleMixItem(items) {
            for (let i = 0; i < items.length; i++) {
                items[i].classList.remove('mix-item--hidden');
                items[i].classList.add('is-appeared-top');
            }
        }

        function setHideMixItem(items) {
            for (let i = 0; i < items.length; i++) {
                items[i].classList.add('mix-item--hidden');
            }
        }

        function unsetAppearedTop(items) {
            for (let i = 0; i < items.length; i++) {
                items[i].classList.remove('is-appeared-top');
            }
        }

        function setActiveClassControl(item) {
            controlItems.forEach(function (i) {
                i.classList.remove('is-active');
                item.classList.add('is-active');
            });
        }

        controlItems.forEach((item) => {
            item.addEventListener('click', function () {
                const mixClasses = this.dataset.filter;

                if (mixClasses === 'all') {
                    unsetAppearedTop(allMixItems);
                    //console.log('all');
                    setTimeout(function () {
                        setVisibleMixItem(allMixItems);
                    }, 300);
                } else {
                    const visibleMixItems = filters[i].querySelectorAll(`.mix-item${mixClasses}`);
                    const hideMixItems = filters[i].querySelectorAll(`.mix-item:not(${mixClasses})`);

                    unsetAppearedTop(allMixItems);
                    setTimeout(function () {
                        setHideMixItem(hideMixItems);
                        setVisibleMixItem(visibleMixItems);
                    }, 300);
                }

                setActiveClassControl(this);
            });
        });
    }
};

export default projectsFilter;
