import validator from 'validator';

const clearErrorData = (selector) => {
    const sibling = selector.nextElementSibling;
    // const prevSibling = selector.previousElementSibling;
    selector.classList.remove('is-error');
    sibling.innerHTML = '';
    // if (prevSibling) {
    //     prevSibling.classList.remove('is-fly');
    // }
    sibling.classList.remove('is-visible');
};

const addErrorData = (selector, errorText) => {
    const sibling = selector.nextElementSibling;
    const prevSibling = selector.previousElementSibling;
    selector.classList.add('is-error');
    sibling.innerHTML = errorText;
    if (prevSibling) {
        prevSibling.classList.add('is-fly');
    }
    sibling.classList.add('is-visible');
};

const formValidation = () => {
    const forms = document.querySelectorAll('.js--form');

    for (let i = 0; i < forms.length; i++) {
        forms[i].classList.add('is-stop-submit');
        forms[i].addEventListener('submit', (e) => {
            const noticesData = [];

            const allInputs = forms[i].querySelectorAll('input');
            for (let n = 0; n < allInputs.length; n++) {
                allInputs[n].addEventListener('change', () => {
                    clearErrorData(allInputs[n]);
                });
            }

            const innSelector = forms[i].querySelector('.js--validator-inn');
            const nameSelector = forms[i].querySelector('.js--validator-name');
            const phoneSelector = forms[i].querySelector('.js--validator-phone');
            const emailSelector = forms[i].querySelector('.js--validator-email');
            const aboutSelector = forms[i].querySelector('textarea[name="about"]');
            const innoSelector = forms[i].querySelector('textarea[name="inno"]');
            const passwordSelector = forms[i].querySelector('.js--validator-password');
            const passwordConfirmSelector = forms[i].querySelector('.js--validator-password-confirm');
            const passwordLoginSelector = forms[i].querySelector('.js--validator-password-login');
            const passwordOldSelector = forms[i].querySelector('.js--validator-password-old');

            // if (innSelector !== null) {
            //     const innData = innSelector.value;
            //     if (validator.isEmpty(innData) === true) {
            //         addErrorData(innSelector, 'Заполните ИНН');
            //         noticesData.push({
            //             type: 'error',
            //             text: 'Заполните ИНН',
            //         });
            //     } else if (
            //         validator.isLength(innData, { min: 10, max: 20 }) === false
            //     ) {
            //         addErrorData(innSelector, 'Должно быть >= 10 цифр');
            //         noticesData.push({
            //             type: 'error',
            //             text: 'Должно быть >= 10 цифр',
            //         });
            //     } else {
            //         clearErrorData(innSelector);
            //     }
            // }

            if (innSelector !== null) {
                const innData = innSelector.value;
                if (validator.isEmpty(innData) === false) {
                    if (
                        validator.isLength(innData, {
                            min: 10,
                            max: 22
                        }) === false
                    ) {
                        addErrorData(innSelector, 'Должно быть от 10 до 22 цифр');
                        noticesData.push({
                            type: 'error',
                            text: 'Должно быть >= 10 цифр',
                        });
                    } else {
                        clearErrorData(innSelector);
                    }
                }
            }

            if (nameSelector !== null) {
                const nameData = nameSelector.value;
                if (validator.isEmpty(nameData) === true) {
                    addErrorData(nameSelector, 'Заполните ФИО');
                    noticesData.push({
                        type: 'error',
                        text: 'Заполните свое имя',
                    });
                } else {
                    clearErrorData(nameSelector);
                }
            }

            if (phoneSelector !== null) {
                const phoneData = phoneSelector.value.replace(/[^+0-9]/gi, '');
                if (validator.isEmpty(phoneData) === true) {
                    addErrorData(phoneSelector, 'Заполните номер телефона');
                    noticesData.push({
                        type: 'error',
                        text: 'Заполните номер телефона',
                    });
                } else if (
                    validator.isLength(phoneData, { min: 12, max: 12 }) === false
                ) {
                    addErrorData(phoneSelector, 'Не хватает цифр в номере');
                    noticesData.push({
                        type: 'error',
                        text: 'Не хватает цифр в номере',
                    });
                } else {
                    clearErrorData(phoneSelector);
                }
            }

            // if (phoneSelector !== null) {
            //     const phoneData = phoneSelector.value.replace(/[^+0-9]/gi, '');
            //     if (validator.isEmpty(phoneData) === false) {
            //         if (
            //             validator.isLength(phoneData, {
            //                 min: 11,
            //                 max: 11
            //             }) === false
            //         ) {
            //             addErrorData(phoneSelector, 'Не хватает цифр в номере');
            //             noticesData.push({
            //                 type: 'error',
            //                 text: 'Не хватает цифр в номере',
            //             });
            //         } else {
            //             clearErrorData(phoneSelector);
            //         }
            //     }
            // }

            if (emailSelector !== null) {
                const emailData = emailSelector.value;
                if (validator.isEmpty(emailData) === true) {
                    addErrorData(emailSelector, 'Заполните адрес почты');
                    noticesData.push({
                        type: 'error',
                        text: 'Заполните адрес почты',
                    });
                } else if (
                    validator.isEmail(emailData) === false
                ) {
                    addErrorData(emailSelector, 'Указан некорректный email');
                    noticesData.push({
                        type: 'error',
                        text: 'Указан некорректный email',
                    });
                } else {
                    clearErrorData(emailSelector);
                }
            }

            if (passwordSelector !== null) {
                const passwordData = passwordSelector.value;
                if (validator.isEmpty(passwordData) === true) {
                    addErrorData(passwordSelector, 'Заполните пароль');
                    noticesData.push({
                        type: 'error',
                        text: 'Заполните пароль',
                    });
                } else if (
                    validator.isLength(passwordData, { min: 8 }) === false
                ) {
                    addErrorData(passwordSelector, 'Должно быть >= 8 символов');
                    noticesData.push({
                        type: 'error',
                        text: 'Должно быть >= 8 символов',
                    });
                }
                else {
                    clearErrorData(passwordSelector);
                }
                if (passwordConfirmSelector !== null) {
                    const passwordConfirmData = passwordConfirmSelector.value;
                    if (validator.isEmpty(passwordConfirmData) === true) {
                        addErrorData(passwordConfirmSelector, 'Повторите пароль');
                        noticesData.push({
                            type: 'error',
                            text: 'Повторите пароль',
                        });
                    } else if (validator.equals(passwordData, passwordConfirmData) === false) {
                        addErrorData(passwordConfirmSelector, 'Пароли не совпадают');
                        noticesData.push({
                            type: 'error',
                            text: 'Пароли не совпадают',
                        });
                    } else {
                        clearErrorData(passwordConfirmSelector);
                    }
                }

                if (passwordOldSelector !== null) {
                    const passwordOldData = passwordOldSelector.value;
                    if (validator.isEmpty(passwordOldData) === true) {
                        addErrorData(passwordOldSelector, 'Введите старый пароль');
                        noticesData.push({
                            type: 'error',
                            text: 'Введите старый пароль',
                        });
                    } else if (validator.isLength(passwordOldData, { min: 8 }) === false) {
                        addErrorData(passwordOldSelector, 'Должно быть >= 8 символов');
                        noticesData.push({
                            type: 'error',
                            text: 'Должно быть >= 8 символов',
                        });
                    } else if (validator.equals(passwordData, passwordOldData) === true) {
                        addErrorData(passwordSelector, 'Сатрый и новый пароль не должны совпадать');
                        noticesData.push({
                            type: 'error',
                            text: 'Пароли не совпадают',
                        });
                    } else {
                        clearErrorData(passwordOldSelector);
                    }
                }
            }

            if (passwordLoginSelector !== null) {
                const passwordLoginData = passwordLoginSelector.value;
                if (validator.isEmpty(passwordLoginData) === true) {
                    addErrorData(passwordLoginSelector, 'Заполните пароль');
                    noticesData.push({
                        type: 'error',
                        text: 'Заполните пароль',
                    });
                } else if (validator.isLength(passwordLoginData, { min: 8 }) === false) {
                    addErrorData(passwordLoginSelector, 'Должно быть >= 8 символов');
                    noticesData.push({
                        type: 'error',
                        text: 'Должно быть >= 8 символов',
                    });
                } else {
                    clearErrorData(passwordLoginSelector);
                }
            }

            if (aboutSelector !== null) {
                const aboutData = aboutSelector.value;
                if (validator.isLength(aboutData, { max: 500 }) === false) {
                    addErrorData(aboutSelector, 'Сократите текст');
                    noticesData.push({
                        type: 'error',
                        text: 'Сократите текст',
                    });
                } else {
                    clearErrorData(aboutSelector);
                }
            }

            if (innoSelector !== null) {
                const innoData = innoSelector.value;
                if (validator.isLength(innoData, { max: 500 }) === false) {
                    addErrorData(innoSelector, 'Сократите текст');
                    noticesData.push({
                        type: 'error',
                        text: 'Сократите текст',
                    });
                } else {
                    clearErrorData(innoSelector);
                }
            }

            if (noticesData.length > 0) {
                e.preventDefault();
                forms[i].classList.add('is-stop-submit');
                forms[i].classList.remove('is-can-submit');
            } else {
                forms[i].classList.remove('is-stop-submit');
                forms[i].classList.add('is-can-submit');
            }
        });
    }
};

export default formValidation;
