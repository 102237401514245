import anime from 'animejs/lib/anime.es';

const animPreloader = () => {
    function getCookie(name) {
        const matches = document.cookie.match(new RegExp(
            `(?:^|; )${name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1')}=([^;]*)`,
        ));
        return matches ? decodeURIComponent(matches[1]) : undefined;
    }

    const preloaderSaw = getCookie('preloader_saw');

    function sloganAnimate() {
        const textWrapper = document.querySelector('.hero__subtitle');
        textWrapper.innerHTML = textWrapper.textContent.replace(/\S/g, "<span class='letter'>$&</span>");
        const tlSlogan = anime.timeline();
        tlSlogan.add({
            targets: '.hero__subtitle-bg',
            easing: 'cubicBezier(0.7,0,0.3,1)',
            duration: 1000,
            translateX: [0, '100%'],
        })
            .add({
                targets: '.letter',
                translateX: [40,0],
                translateZ: 0,
                opacity: [0,1],
                easing: 'cubicBezier(0.7,0,0.3,1)',
                loop: true,
                duration: 600,
                delay: (el, i) => 500 + 30 * i,
            },'-=700');
    }

    if (preloaderSaw !== '1') {
        const tl = anime.timeline({
            begin() {
                document.querySelector('.preloader').style.visibility = 'visible';
                document.querySelector('.preloader-ajax').style.visibility = 'hidden';
            },
            complete() {
                document.querySelector('.preloader').style.visibility = 'hidden';
                document.cookie = 'preloader_saw=1; path=/;';
                const elemContent = document.querySelector('.content');
                if (elemContent.style.removeProperty) {
                    elemContent.style.removeProperty('transform');
                } else {
                    elemContent.style.removeAttribute('transform');
                }
                const mobileMenu = document.querySelector('.mobile-menu');
                mobileMenu.classList.add('is-ready');
                if (document.querySelector('.hero__subtitle')) {
                    sloganAnimate();
                }
            },

        });
        tl
            .add({
                targets: '.preloader__logo',
                easing: 'cubicBezier(0.7,0,0.3,1)',
                duration: 1000,
                translateY: [800, 0],
                opacity: [0, 1],
            })
            .add({
                targets: '.loader',
                easing: 'cubicBezier(0.7,0,0.3,1)',
                duration: 1000,
                translateY: [800, 0],
                opacity: [0, 1],
            }, 200)
            .add({
                targets: '.loader__circle',
                strokeDashoffset: [anime.setDashoffset, 0],
                easing: 'cubicBezier(.8,.13,.49,.93)',
                duration: 1500,
                delay(el, i) { return i * 250; },
                direction: 'alternate',
            })
            .add({
                targets: '.preloader__logo',
                easing: 'cubicBezier(0.7,0,0.3,1)',
                duration: 600,
                opacity: 0,
                translateY: [0, '-100%'],
            })
            .add({
                targets: '.loader',
                easing: 'cubicBezier(0.7,0,0.3,1)',
                duration: 600,
                opacity: 0,
                scale: 0.3,
                translateY: [0, '-100%'],
            }, '-=600')
            .add({
                targets: '.preloader__main',
                easing: 'cubicBezier(0.7,0,0.3,1)',
                duration: 1000,
                translateY: [0, '-100%'],
            }, '-=600')
            .add({
                targets: '.preloader__switcher',
                easing: 'cubicBezier(0.7,0,0.3,1)',
                duration: 400,
                translateY: [0, '-100%'],
            })
            .add({
                targets: '.content',
                easing: 'cubicBezier(0.7,0,0.3,1)',
                duration: 1000,
                translateY: [-60, 0],
                opacity: [0, 1],
            }, '-=500');
    }
};

const animPreloaderAjax = () => {
    function getCookie(name) {
        const matches = document.cookie.match(new RegExp(
            `(?:^|; )${name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1')}=([^;]*)`,
        ));
        return matches ? decodeURIComponent(matches[1]) : undefined;
    }

    const preloaderSaw = getCookie('preloader_saw');

    function sloganAnimate() {
        const textWrapper = document.querySelector('.hero__subtitle');
        textWrapper.innerHTML = textWrapper.textContent.replace(/\S/g, "<span class='letter'>$&</span>");
        const tlSlogan = anime.timeline();
        tlSlogan.add({
            targets: '.hero__subtitle-bg',
            easing: 'cubicBezier(0.7,0,0.3,1)',
            duration: 1000,
            translateX: [0, '100%'],
        })
            .add({
                targets: '.letter',
                translateX: [40,0],
                translateZ: 0,
                opacity: [0,1],
                easing: 'cubicBezier(0.7,0,0.3,1)',
                loop: true,
                duration: 600,
                delay: (el, i) => 500 + 30 * i,
            },'-=700');
    }

    if (preloaderSaw === '1') {
        const tl = anime.timeline({
            begin() {
                document.querySelector('.preloader').style.visibility = 'hidden';
                document.querySelector('.preloader-ajax').style.visibility = 'visible';
                document.querySelector('.preloader-ajax__logo').style.opacity = '1';
            },
            complete() {
                document.querySelector('.preloader-ajax').style.visibility = 'hidden';
                const elemContent = document.querySelector('.content');
                if (elemContent.style.removeProperty) {
                    elemContent.style.removeProperty('transform');
                } else {
                    elemContent.style.removeAttribute('transform');
                }
                const mobileMenu = document.querySelector('.mobile-menu');
                mobileMenu.classList.add('is-ready');
                if (document.querySelector('.hero__subtitle')) {
                    sloganAnimate();
                }
            },

        });
        tl
            .add({
                targets: '.preloader-ajax__line1',
                easing: 'cubicBezier(.8,.13,.49,.93)',
                duration: 400,
                fill: '#F14B1C',
            })
            .add({
                targets: '.preloader-ajax__line2',
                easing: 'cubicBezier(.8,.13,.49,.93)',
                duration: 400,
                fill: '#F14B1C',
            })
            .add({
                targets: '.preloader-ajax__line3',
                easing: 'cubicBezier(.8,.13,.49,.93)',
                duration: 400,
                fill: '#F14B1C',
            })
            .add({
                targets: '.preloader-ajax__line4',
                easing: 'cubicBezier(.8,.13,.49,.93)',
                duration: 400,
                fill: '#F14B1C',
            })
            .add({
                targets: '.preloader-ajax__logo',
                easing: 'cubicBezier(0.7,0,0.3,1)',
                duration: 600,
                opacity: 0,
                translateY: [0, '-100%'],
            })
            .add({
                targets: '.preloader-ajax__main',
                easing: 'cubicBezier(0.7,0,0.3,1)',
                duration: 1000,
                translateY: [0, '-100%'],
            }, '-=600')
            .add({
                targets: '.preloader-ajax__switcher',
                easing: 'cubicBezier(0.7,0,0.3,1)',
                duration: 400,
                translateY: [0, '-100%'],
            })
            .add({
                targets: '.content',
                easing: 'cubicBezier(0.7,0,0.3,1)',
                duration: 1000,
                translateY: [-60, 0],
                opacity: [0, 1],
            }, '-=500');
    }
};

export { animPreloader, animPreloaderAjax };
