function notification(wrapper, type, message) {
    wrapper.find('.muar').addClass('is-visible');
    wrapper.find(`.notification--${type}`).addClass('is-visible');
    if (message) {
        if (message.indexOf('Email has already been taken.') > 0) {
            wrapper.find(`.notification--${type} .notification__text`).html('Email уже занят');
        } else {
            wrapper.find(`.notification--${type} .notification__text`).html(message);
        }
    }
    setTimeout(function () {
        wrapper.find('.muar').removeClass('is-visible');
        wrapper.find(`.notification--${type}`).removeClass('is-visible');
        wrapper.find('.notification__text').html('');
    }, 3000);
}

function formChangePassword() {
    $('.js--form-change-password').on('submit', function (e) {
        e.preventDefault();
        const form = $(this);

        if(form.hasClass('is-can-submit')) {
            $(this).request('ChangePassword::onAjax', {
                success: function (response) {
                    if(response.status === true) {
                        notification(form, 'success', 'Пароль успешно изменен');
                    } else if (response.status === false) {
                        notification(form, 'error', response.message);
                    }
                },
                error: function () {
                    notification(form, 'error', 'Непредвиденная ошибка. Обратитесь к менеджеру');
                },
            });
        }
    });
}

export default formChangePassword;
