const flyLabel = () => {
    const labels = document.querySelectorAll('.js--fly-label');
    for(let i = 0; i < labels.length; i++) {
        const labelSibling = labels[i].nextElementSibling;
        labelSibling.onfocus = function() {
            this.previousElementSibling.classList.add('is-fly');
        };
        labelSibling.onblur = function() {
            if(this.value === '' || this.value === '+7 (') {
                this.previousElementSibling.classList.remove('is-fly');
            } else {
                this.previousElementSibling.classList.add('is-fly');
            }
        };
        labelSibling.onchange = function () {
            if(this.value === '' || this.value === '+7 (') {
                this.previousElementSibling.classList.remove('is-fly');
            } else {
                this.previousElementSibling.classList.add('is-fly');
            }
        };
    }
};

export default flyLabel;
