import Swiper from 'swiper';

const gallerySlider = () => {
    const swiper = new Swiper('.js--gallery-slider', {
        slidesPerView: 'auto',
        spaceBetween: 32,
        centeredSlides: true,
        loop: true,
        pagination: {
            el: '.js--gallery-slider-nav .swiper-control__fraction',
            type: 'fraction',
            currentClass: 'swiper-control__fraction-current',
            totalClass: 'swiper-control__fraction-total',
            disabledClass: 'is-disabled',
            hiddenClass: 'is-hidden',
            lockClass: 'is-lock',
            formatFractionCurrent(number) {
                if (number < 10) {
                    number = `0${number}`;
                }
                return number;
            },
            formatFractionTotal(number) {
                if (number < 10) {
                    number = `0${number}`;
                }
                return number;
            },
        },
        navigation: {
            nextEl: '.js--gallery-slider-nav .swiper-control__next',
            prevEl: '.js--gallery-slider-nav .swiper-control__prev',
            disabledClass: 'is-disabled',
            hiddenClass: 'is-hidden',
            lockClass: 'is-lock',
        },
    });
};

export default gallerySlider;

