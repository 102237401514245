const modals = () => {
    const body = document.querySelector('body');
    const allModals = document.querySelectorAll('.modal');
    const closeModalButtons = document.querySelectorAll('.js--modal-close');
    const openModalButtons = document.querySelectorAll('.js--modal-open');

    const closeModal = () => {
        const currentModal = document.querySelector('.modal.is-show');
        if (currentModal.querySelector('.modal__video')) {
            const video = currentModal.querySelector('.modal__video');
            video.pause();
        }
        currentModal.classList.remove('is-show');
        body.classList.remove('is-modal');
    };

    const openModal = (selector, title, service) => {
        for (let i = 0; i < allModals.length; i++) {
            if (allModals[i].classList.contains(`js--modal-${selector}`)) {
                allModals[i].classList.add('is-show');
                if (title || service) {
                    allModals[i].querySelector('.modal__title').innerHTML = title;
                    allModals[i].querySelector("input[name='service']").value = service;
                }
                body.classList.add('is-modal');
            }
        }
    };

    for (let i = 0; i < openModalButtons.length; i++) {
        openModalButtons[i].addEventListener('click', function () {
            const title = this.dataset.modalTitle;
            const service = this.dataset.modalService;
            openModal(this.dataset.modalTarget, title, service);
        });
    }

    for (let i = 0; i < closeModalButtons.length; i++) {
        closeModalButtons[i].addEventListener('click', () => {
            closeModal();
        });
    }

    document.addEventListener('click', (e) => {
        const { target } = e;

        if (target && !target.closest('.js--modal-open') && !target.closest('.modal__container') && body.classList.contains('is-modal') && !target.closest('.dz-hidden-input')) {
            closeModal();
        }
    });
};

export default modals;
